import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as GatsbyTypes from '../graphqlTypes';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import RatesMap from '../components/RatesMap';
import RatesList from '../components/RatesList';
import PageTitle from '../components/PageTitle';
import CenteredBox from '../components/CenteredBox';
import styled, { scale, BREAKPOINTS } from '../styled';
import RatesConditions from '../components/RatesConditions';
import ServicesBlock, { ServiceBlockTypes } from '../components/ServicesBlock';
import RequestQuoteBlock from '../components/RequestQuoteBlock';
import ReassuranceBlock from '../components/ReassuranceBlock';

const Heading = styled.h2`
  margin-top: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.m}) {
    margin-top: ${scale(2)};
  }
`;

const RatesPage: React.FC = () => {
  const { file } = useStaticQuery<GatsbyTypes.GetRatesPageQuery>(
    graphql`
      query GetRatesPage {
        file(name: { eq: "rates" }) {
          id
          childMarkdownRemark {
            id
            frontmatter {
              title
              zones {
                title
                description
                price
                coordinates
              }
              conditions
              seo {
                title
                description
                image {
                  publicURL
                }
              }
            }
          }
        }
      }
    `
  );

  const seoData = file?.childMarkdownRemark?.frontmatter?.seo;
  /* istanbul ignore next */
  if (!seoData?.title || !seoData.description) {
    throw new Error('[Missing data] Page SEO meta');
  }

  const pageData = file?.childMarkdownRemark?.frontmatter;
  /* istanbul ignore next */
  if (!pageData?.title) {
    throw new Error('[Missing data] Page content');
  }

  return (
    <Layout hasBlocks={false}>
      <SEO
        title={seoData.title}
        description={seoData.description}
        image={seoData.image?.publicURL}
      />
      <PageTitle>{pageData.title}</PageTitle>
      <CenteredBox>
        <Heading>Service de livraison ponctuelle</Heading>
        <RatesList zones={pageData.zones as GatsbyTypes.MarkdownRemarkFrontmatterZones[]} />
        <RatesMap zones={pageData.zones as GatsbyTypes.MarkdownRemarkFrontmatterZones[]} />
        <RatesConditions>{pageData.conditions}</RatesConditions>
      </CenteredBox>
      <ServicesBlock
        title="Nos services sur devis"
        data-testid="block-services"
        displayedBlockTypes={[ServiceBlockTypes.MailService, ServiceBlockTypes.PackageDelivery]}
      />
      <RequestQuoteBlock data-testid="block-request-quote" />
      <ReassuranceBlock id="block-reassurance" data-testid="block-reassurance" />
    </Layout>
  );
};

export default RatesPage;
