import React from 'react';
import { SerializedStyles } from '@emotion/react';

import styled, { baseline, BREAKPOINTS, scale, hachureStyles } from '../styled';

const Layout = styled.div`
  padding: ${scale(0.5)};

  ${({ theme }): SerializedStyles => hachureStyles(theme.hachure.m)};
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: ${scale(1)};
  padding-left: ${scale(2)};
  margin-bottom: 0;

  ${baseline(14, 1, 'm')};
  background-color: #fff;

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(16, 1, 'l')};
  }
`;

const Item = styled.li`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.s}) {
    width: 50%;
  }
`;

const RatesConditions: React.FC = ({ children, ...props }) => {
  return (
    <Layout {...props}>
      <List>
        {React.Children.map(children, (child) => {
          return <Item>{child}</Item>;
        })}
      </List>
    </Layout>
  );
};

export default RatesConditions;
