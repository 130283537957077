import React from 'react';

import * as GatsbyTypes from '../graphqlTypes';
import styled, { scale, baseline, BREAKPOINTS, zoneColors } from '../styled';

const Layout = styled.div`
  margin-bottom: ${scale(1)};
`;

const ZoneList = styled.div`
  margin-top: ${scale(1)};
  margin-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.m}) {
    margin-top: ${scale(2)};
    margin-bottom: ${scale(2)};
  }
`;

const Zone = styled.p`
  text-align: center;

  @media (min-width: ${BREAKPOINTS.m}) {
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: ${scale(0.5)};
    grid-template-areas:
      'title stripes price'
      'description description description';

    text-align: inherit;
  }
`;

const Price = styled.span`
  display: block;

  font-weight: 800;
  ${baseline(26, 2, 'm')};

  @media (min-width: ${BREAKPOINTS.m}) {
    grid-area: price;

    ${baseline(36, 2, 'l')};
  }
`;

const Taxes = styled.span`
  font-size: 1rem;
  font-weight: normal;
`;

const Title = styled.span`
  display: block;

  font-weight: bold;
  ${baseline(26, 2, 'm')};
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.m}) {
    grid-area: title;

    ${baseline(36, 2, 'l')};
  }
`;

const Description = styled.span`
  display: block;

  color: #000;

  @media (min-width: ${BREAKPOINTS.m}) {
    grid-area: description;
  }
`;

const Stripes = styled.span`
  display: block;
  position: relative;
  height: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.m}) {
    grid-area: stripes;

    height: ${scale(2)};
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;

    height: 1px;
    background-color: #000;
  }

  &:before {
    top: ${scale(0.375)};

    @media (min-width: ${BREAKPOINTS.m}) {
      top: ${scale(1.25)};
    }
  }

  &:after {
    bottom: ${scale(0.375)};

    @media (min-width: ${BREAKPOINTS.m}) {
      bottom: ${scale(0.5)};
    }
  }
`;

const RatesList: React.FC<{ zones: GatsbyTypes.MarkdownRemarkFrontmatterZones[] }> = ({
  zones,
}) => {
  return (
    <Layout>
      <p>La zone de livraison est divisée en trois parties :</p>
      <ZoneList>
        {zones.map((zone, index) => {
          /* istanbul ignore next */
          if (!zone.title) return;

          return (
            <Zone key={zone.title} style={{ color: zoneColors[index] }}>
              <Title>{zone.title}</Title>
              <Description>{zone.description}</Description>
              <Price>
                {zone.price} € <Taxes>HT</Taxes>
              </Price>
              <Stripes />
            </Zone>
          );
        })}
      </ZoneList>
    </Layout>
  );
};

export default RatesList;
