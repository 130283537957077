import React from 'react';

import * as GatsbyTypes from '../graphqlTypes';
import styled, { scale, useTheme, zoneColors } from '../styled';
import { loadGoogleMapsLibrary } from '../googleMaps';

const Layout = styled.div`
  margin-bottom: ${scale(1)};
  height: ${scale(16)};

  border-radius: ${scale(2)};
`;

const RatesMap: React.FC<{ zones: GatsbyTypes.MarkdownRemarkFrontmatterZones[] }> = ({ zones }) => {
  const { color } = useTheme();
  const mapRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    /* istanbul ignore next */
    function handleOnLoad(): void {
      if (!mapRef?.current) return;

      const map = new window.google.maps.Map(mapRef.current, {
        backgroundColor: color.gray.s,
        disableDefaultUI: true,
        minZoom: 12,
      });
      const bounds = new google.maps.LatLngBounds();
      const infoWindows: google.maps.InfoWindow[] = [];
      let zIndexes = zones.length;

      zones.map((zone, index) => {
        if (!zone.coordinates) return;

        const coordinates = JSON.parse(zone.coordinates);
        const polygonLatLng = coordinates.map(([lng, lat]: number[]) => {
          const LatLng = new google.maps.LatLng(lat, lng);

          bounds.extend(LatLng);
          return LatLng;
        });

        const zonePolygon = new google.maps.Polygon({
          paths: polygonLatLng,
          strokeColor: zoneColors[index],
          strokeOpacity: 0.75,
          strokeWeight: 2,
          fillColor: zoneColors[index],
          fillOpacity: 0.25,
          zIndex: --zIndexes,
        });
        zonePolygon.setMap(map);

        google.maps.event.addListener(zonePolygon, 'click', function (event) {
          if (!zone.title) return;

          infoWindows.map((infoWindow) => {
            infoWindow.close();
          });

          const infoWindow = new google.maps.InfoWindow({
            content: `<span style="text-transform: uppercase; color: ${zoneColors[index]}; font-weight: bold;">${zone.title} · ${zone.price}€ HT</span>`,
            position: event.latLng,
          });
          infoWindows.push(infoWindow);
          infoWindow.open(map);
        });
      });
      map.fitBounds(bounds);
    }

    /* istanbul ignore next */
    if (typeof window.google === 'undefined') {
      loadGoogleMapsLibrary(handleOnLoad);
    } else {
      handleOnLoad();
    }
  }, [color.gray.s, zones]);

  return <Layout ref={mapRef} />;
};

export default RatesMap;
